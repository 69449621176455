import React from "react";
import { NavDropdown, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Logo from "./neteera_logo.png";

class Header extends React.Component {

    logoutClickEvent() {
        localStorage.clear();
        window.location.href = "/login"
    }
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            userRole: ''
        };
    }

    componentDidMount() {
        // 从 localStorage 获取用户名并设置到 state 中
        const username = localStorage.getItem("username");
        const userRole = localStorage.getItem("userRole");

        this.setState({
            userRole: userRole,
            username: username
        });
    }

    render() {
        const { userRole } = this.state;
        return (
            <>
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container fluid>
                        <Navbar.Brand>
                            <Nav.Link as={NavLink} to="/" exact="true" >
                                <img
                                    className="img-thumbnail mx-auto d-block mb-2" style={{ height: "50px" }}
                                    src={Logo}
                                    alt="logo"
                                />
                            </Nav.Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                variant="underline">
                                <Nav.Link as={NavLink} to="/" exact="true" >Dashboard</Nav.Link>
                                {userRole <= "2" && (
                                    <>
                                        <Nav.Link as={NavLink} to="/users">Users</Nav.Link>
                                        <Nav.Link as={NavLink} to="/analytics" >Analytics</Nav.Link>
                                        <Nav.Link as={NavLink} to="/data-logs" >Data Log</Nav.Link>
                                        <Nav.Link as={NavLink} to="/pos" >POs</Nav.Link>
                                        {/* <Nav.Link as={NavLink} to="/settings" >Settings</Nav.Link> */}
                                        
                                    </>
                                )}
                                {userRole === "1" && (
                                    <NavDropdown title="Settings" id="collapsible-nav-dropdown" className="justify-content-end me-4">
                                        <NavDropdown.Item as={NavLink} to="/products">Products</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to="/station-types">Station Types</NavDropdown.Item>
                                        {/* <NavDropdown.Item as={NavLink} to="/station-components">Station Component</NavDropdown.Item> */}
                                        <NavDropdown.Item as={NavLink} to="/stations">Stations</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to="/fw-versions">FW version</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to="/lines">Lines</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to="/factories">Factories</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to="/subcontractors">Subcontractors</NavDropdown.Item>

                                        {/* CRC 部分
                                        <NavDropdown.Divider />
                                        <NavDropdown title="CRC" id="collapsible-nav-dropdown-crc" className="dropend">
                                            <NavDropdown.Item as={NavLink} to="#">CRC-1</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="#">CRC-2</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="#">CRC-3</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="#">CRC-4</NavDropdown.Item>
                                        </NavDropdown> */}
                                    </NavDropdown>
                                )}
                            </Nav>
                            <Nav>
                                <NavDropdown title={this.state.username} id="collapsible-nav-dropdown" className="justify-content-end me-4">
                                    <NavDropdown.Item href="/user">Settings</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.logoutClickEvent}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>

                    </Container>
                </Navbar >
            </>
        );
    }
}

export default Header;