import React from "react";
import DataTable from "react-data-table-component";

class PoTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            manualSelection: false, // 判斷是否是手動選擇
            dataLoaded: false,      // 判斷是否是第一次載入資料
        };
        this.selectRow = [];
    }

    componentDidMount() {
        // 初次掛載後嘗試選中第一行
        if (this.props.data.length > 0) {
            this.selectFirstRow();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("componentDidUpdate");
        // 當 data 尚未載入且 props.data 發生變化時執行
        if (
            !this.state.dataLoaded &&
            prevProps.data !== this.props.data &&
            this.props.data.length > 0
        ) {
            // console.log("componentDidUpdate - selectFirstRow");
            this.selectFirstRow();
        }
    }

    selectFirstRow = async () => {
        // console.log("selectFirstRow");
        const firstRow = this.props.data[0];
        this.selectRow = firstRow;

        // 如果已經選中了第一行，避免重複執行
        if (
            this.state.selectedRows.length === 1 &&
            this.state.selectedRows[0].id === firstRow.id
        ) {
            // console.log("selectFirstRow - return");
            return;
        }

        // 更新狀態並觸發父組件的回調
        await this.setState({
            selectedRows: [firstRow],
            dataLoaded: true, // 標記資料已加載
        });

        await this.props.onRowSelection([firstRow]);
    };

    handleChange = ({ selectedRows }) => {
        // console.log("handleChange");
        // 僅在選中行發生變化時執行
        if (JSON.stringify(selectedRows) !== JSON.stringify(this.state.selectedRows)) {
            // console.log("handleChange !==");
            this.setState({ selectedRows, manualSelection: true });
            this.props.onRowSelection(selectedRows);
        }
    };

    columns = [
        // 列定义保持不变
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>PO#</strong>,
            selector: row => row.number,
            width: '110px',
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.number}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Site</strong>,
            selector: row => row.factory_name,
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.factory_name}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Start date</strong>,
            selector: row => row.started_at ? row.started_at : '-',
            width: '110px',
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.started_at}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Finished date</strong>,
            selector: row => row.finished_at ? row.finished_at : '-',
            width: '110px',
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.finished_at}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Total in PO</strong>,
            selector: row => row.amount,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Passed</strong>,
            selector: row => row.passed,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Failed</strong>,
            selector: row => row.failed,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Ready For Shipment</strong>,
            selector: row => row.ready,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Shipped</strong>,
            selector: row => row.shipped,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>In Progress</strong>,
            selector: row => row.progress,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Yield</strong>,
            selector: row => row.yield,
        }
    ];
 

    render() {
        // 判斷某行是否應被選中
        const rowSelectCritera = (row) => {
            return this.state.selectedRows.some(selectedRow => selectedRow.id === row.id);
        };

        return (
            <DataTable
                pagination
                paginationPerPage={4}
                paginationRowsPerPageOptions={[4, 10, 20, 30]}
                paginationComponentOptions={{
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'ALL',
                }}
                highlightOnHover
                selectableRows
                selectableRowsSingle
                columns={this.columns}
                data={this.props.data}
                onSelectedRowsChange={this.handleChange}
                selectableRowSelected={rowSelectCritera} // 用於標記選中行
            />
        );
    }
}

export default PoTable;
