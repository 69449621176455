import React, { useState, useEffect } from "react";
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ProductsTable from '../../components/ProductsTable.js';
import Forbidden from "../../components/Forbidden.js";
import PosTable from "../../components/PosTable.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faCheckCircle, faCheckSquare, faFileArrowUp, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FaCheckSquare } from "react-icons/fa";


const token = localStorage.getItem('token');
const headers = {
    'Authorization': `Bearer ${token}`, // 替換為你的 token
    'Content-Type': 'application/json',
};




const onClickShipped = async (e) => {
    const pid = e.currentTarget.getAttribute("pid"); // 使用 currentTarget 確保拿到 a 標籤的屬性
    const { value: file } = await Swal.fire({
        title: "Select Shipped CSV File",
        input: "file",
        inputAttributes: {
            accept: ".csv",
        },
        showCancelButton: true,
    });

    if (file) {
        // 顯示 loading 視窗
        Swal.fire({
            title: 'Uploading...',
            onBeforeOpen: () => {
                Swal.showLoading();
            },
            showConfirmButton: false,
        });

        // FormData 用於上傳檔案
        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await axios.post(
                `${window.api_url}/pos/${pid}/upload-shipped-csv`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, // 替換為你的 token
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            Swal.fire({
                title: 'Done!',
                html: response.data.data,
                icon: 'success',
                showConfirmButton: true,
            }).then(function () {
                window.location.reload();
            });

        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to upload file.',
                icon: 'error',
                showConfirmButton: true,
            });
            console.error("Upload Error:", error);
        }
    }

}

const onClickFinished = (e) => {
    const pid = e.currentTarget.getAttribute("pid"); // 使用 currentTarget 確保拿到 a 標籤的屬性
    Swal.fire({
        title: "Are you sure?",
        text: "This PO will be marked as finished and is the current time",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!"
    }).then((result) => {
        if (result.isConfirmed) {

            axios.put(`${window.api_url}/pos/${pid}/finished`, {}, { headers })
                .then((response) => {
                    if (response.data.code === "0000") {
                        Swal.fire({
                            title: "Finished!",
                            icon: "success",
                            timer: 3000
                        }).then(() => {
                            window.location.reload();
                        });
                    }

                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                });

        }
    });

}

const onClickUpload = async (e) => {
    const pid = e.currentTarget.getAttribute("pid"); // 使用 currentTarget 確保拿到 a 標籤的屬性
    const { value: file } = await Swal.fire({
        title: "Select CSV File",
        input: "file",
        inputAttributes: {
            accept: ".csv",
        },
        showCancelButton: true,
    });

    if (file) {
        // 顯示 loading 視窗
        Swal.fire({
            title: 'Uploading...',
            onBeforeOpen: () => {
                Swal.showLoading();
            },
            showConfirmButton: false,
        });

        // FormData 用於上傳檔案
        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await axios.post(
                `${window.api_url}/pos/${pid}/upload-csv`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, // 替換為你的 token
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            Swal.fire({
                title: 'Done!',
                text: 'File uploaded successfully.',
                icon: 'success',
                showConfirmButton: true,
            }).then(function () {
                window.location.reload();
            });

        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to upload file.',
                icon: 'error',
                showConfirmButton: true,
            });
            console.error("Upload Error:", error);
        }
    }
};

 
const List = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据

    useEffect(() => {
        // 發送帶有 headers 的請求
        axios.get(`${window.api_url}/pos`, { headers })
            .then((response) => {
                if (response.data.code === "0000") {

                    //console.log(response.data.data)
                    if (response.data.data != null && response.data.data.length > 0) {
                        var rows = response.data.data;
                        rows.map((row) => {
                            row.action = <>
                                <a className="btn btn-sm btn-link" href={`/pos/${row.id}/edit`}>Edit</a>
                                <span style={{ padding: "0 8px" }}>|</span>
                                <a className="btn btn-sm btn-primary" pid={row.id} title="Upload SN device, set status to start" onClick={onClickUpload}>
                                    <FontAwesomeIcon icon={faFileArrowUp} /></a>

                                <span style={{ padding: "0 8px" }}>|</span>
                                <a className="btn btn-sm btn-secondary" pid={row.id} onClick={onClickShipped} title="Upload SN device, set status to shiped">
                                    <FontAwesomeIcon icon={faTruckFast} />
                                </a>
                                {!row.finished_at && (
                                    <>
                                        <span style={{ padding: "0 8px" }}>|</span>
                                        <a className="btn btn-sm btn-success" pid={row.id} onClick={onClickFinished} title="PO Finished">
                                            <FontAwesomeIcon icon={faCalendarCheck} />
                                        </a>
                                    </>
                                )}

                            </>
                            return null;
                        })
                        setData(rows);
                    }
                    setLoading(false)

                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
            });

    }, []);

    if (loading) {
        return Swal.showLoading();
    }
    Swal.close()

    if (window.userRole !== "1" && window.userRole !== "2") {
        return (<><span>123</span><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Pos Table</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Row>
                <h4>Pos Table</h4>
            </Row>
            <PosTable data={data} />
            <div style={{ textAlign: "right" }}>
                <a href="/upload_sn_example.csv" download>Download CSV Template</a>
            </div>
        </>
    );

}


export default List