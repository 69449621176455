import React, { useState, useEffect } from 'react';
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2'
import Forbidden from '../../components/Forbidden.js';
import { format } from 'date-fns';


const PoForm = () => {
    const { id } = useParams();

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    const [errors, setErrors] = useState('');
    const [factoriesOptions, setFactoriesOptions] = useState([]);

    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`, // 替換為你的 token
        'Content-Type': 'application/json',
    };


    const [formData, setFormData] = useState({
        number: '',
        site: "",
        amount: "",
        started_at: "",
        finished_at: "",
        id: id
    });
    const handleSelectChange = (selectedOption, actionMeta) => {
        // 检查 actionMeta 是否存在
        if (actionMeta) {
            const { name } = actionMeta || {};
            if (name) {
                // 更新 formData 中的值
                setFormData({
                    ...formData,
                    [name]: selectedOption ? selectedOption.value : ''  // 单选框处理
                });
            }
        } else {
            console.error('没有提供 actionMeta 或 selectedOption。');
        }
    };

    const handleChange = (e, actionMeta) => {
        const { name, value, options, type, tagName } = e.target || {};

        // 處理多選下拉菜單
        if (actionMeta && actionMeta.action === 'select-multi') {
            setFormData({
                ...formData,
                [actionMeta.name]: actionMeta.selectedOptions ? actionMeta.selectedOptions.map(option => option.value) : [],
            });

            // 處理多選框
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                [name]: Array.from(options).filter(option => option.selected).map(option => option.value),
            });

            // 處理文本相關的輸入框和其他簡單輸入
        } else if (
            ['text', 'email', 'number', 'password', 'date', 'radio', 'select-one'].includes(type) ||
            tagName === 'TEXTAREA'
        ) {
            setFormData({ ...formData, [name]: value });

        };
    }



    useEffect(() => {
        if (errors) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: errors,
            });
        }
    }, [errors]);

    const formatDate = (dateString) => {
        if (dateString == null) {
            return "";
        }
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd');
    };
    useEffect(() => {

        axios.get(`${window.api_url}/factories`, { headers }).then(function(factoriesResponse){
            if (factoriesResponse.data.code === "0000") {
                const factoriesOption = factoriesResponse.data.data.content.map(factory => ({
                    value: factory.id,
                    label: factory.name,
                }));
                setFactoriesOptions(factoriesOption);
            }
        })
        if (id) {
            // 使用 Promise.all 处理多个请求
            Promise.all([
                axios.get(`${window.api_url}/pos/${id}`, { headers, }),
            ])
                .then(([response]) => {

                    if (response.data.code === "0000") {


                        let data = {
                            number: response.data.data.number,
                            site: response.data.data.factory.id,
                            amount: response.data.data.amount,
                            started_at: formatDate(response.data.data.started_at),
                            finished_at: formatDate(response.data.data.finished_at),
                            id: id
                        }

                        setFormData(data);
                    }
                    setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else if (error.response && error.response.status === 404) {
                        Swal.fire("UNDEFINED").then(() => {
                            window.location.href = "/pos";
                        });
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                    console.error("获取数据时出错了！", error);
                    setLoading(false); // 请求出错，更新 loading 状态为 false
                });

        } else {
            setLoading(false);
        }
    }, [id]); // 空依赖项数组表示这个 useEffect 只会运行一次


    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.name === "") {
            setErrors('Name is Required');
        }
        else {
            setErrors('');


            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/x-www-form-urlencoded',
            };
            //console.log(id);
            if (id) {

                axios.put(`${window.api_url}/pos/${id}`, formData, { headers },)
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/pos";
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            } else {

                axios.post(`${window.api_url}/pos`, formData, { headers })
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/pos";
                        });
                    })
                    .catch(error => {
                        // 處理錯誤
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            }
        }
    }; 

    if (loading) {
        return Swal.showLoading(); // 显示加载状态
    }
    Swal.close()
    if (window.userRole !== "1" && window.userRole !== "2") {
        return (<><span>123</span><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>POs Form</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Container style={{ 'backgroundColor': 'white', "padding": "30px" }} className="mt-5">
                <Row>
                    <h4 className="mt-3">Po Form</h4>
                </Row>

                <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Number <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name='number' value={formData.number || ''} placeholder="number" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Site <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Select
                                options={factoriesOptions}
                                value={factoriesOptions.find(option => option.value === formData.site)} // 设置选中的值
                                onChange={(factoriesOptions) => handleSelectChange(factoriesOptions, { name: 'site' })}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Start Date
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="date" name='started_at' value={formData.started_at || ''} placeholder="started date" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Finshed Date
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="date" name='finished_at' value={formData.finished_at || ''} placeholder="finished date" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Total
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" name='amount' value={formData.amount || ''} placeholder="total" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    {errors && (
                        <Row>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors}</span>
                            </Col>
                        </Row>
                    )}
                    <div style={{ "textAlign": "right" }}>
                        <Button type="submit">Submit</Button>
                    </div>

                </Form>
            </Container>
        </>
    );

}

export default PoForm