import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home.js';
import Analytics from './pages/Analytics.js';
import UserList from './pages/Users/List.js';
import UserForm from './pages/Users/Form.js';
import Settings from './pages/Settings.js';
import DataLogs from './pages/DataLogs.js';
import Login from './pages/Login/Login.js';
import User from './pages/User.js';
import FactoriesList from './pages/factories/List.js';
import FactoriesForm from './pages/factories/Form.js';
import SubcontractorsList from './pages/subcontractors/List.js';
import SubcontractorsForm from './pages/subcontractors/Form.js';
import StationsList from './pages/stations/List.js';
import StationsForm from './pages/stations/Form.js';
import StationsComponentList from './pages/stationComponents/List.js';
import StationsComponentForm from './pages/stationComponents/Form.js';
import FwVersionComponentList from './pages/fwVersion/List.js';
import FwVersionComponentForm from './pages/fwVersion/Form.js';
import LineList from './pages/lines/List.js';
import LineForm from './pages/lines/Form.js';
import ProductList from './pages/products/List.js';
import ProductForm from './pages/products/Form.js';
import StationTypeList from './pages/station-types/List.js';
import StationTypeForm from './pages/station-types/Form.js';
import PoList from './pages/pos/List.js';
import PoForm from './pages/pos/Form.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Forbidden from './components/Forbidden.js';
import NotFoundPage from './components/NotFoundPage.js';
// import WebSocketManager from './components/WebSocketManager.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function App() {// 在組件中設置全局變數

  if (window.location.hostname === '127.0.0.1' || window.location.hostname === "localhost") {
    window.api_url = "//127.0.0.1:8080/mnf/v1";
    window.backend_url = "//127.0.0.1:8080";
    window.ws_url = "ws://127.0.0.1:8080";
    // window.api_url = "//pd-dashboard-back.neteera.com/mnf/v1";
    // window.api_url = "//pd-dashboard-test-back.neteera.com/mnf/v1";
  } else if (window.location.hostname === "pd-dashboard-test.neteera.com") {
    window.api_url = "//pd-dashboard-test-back.neteera.com/mnf/v1";
    window.backend_url = "//pd-dashboard-test-back.neteera.com";
    window.ws_url = "ws://pd-dashboard-test-back.neteera.com";
  } else if (window.location.hostname === "pd-dashboard.neteera.com") {
    window.api_url = "//pd-dashboard-back.neteera.com/mnf/v1";
    window.backend_url = "//pd-dashboard-back.neteera.com";
    window.ws_url = "ws://pd-dashboard-back.neteera.com";
  }
  window.userRole = localStorage.getItem("userRole");

  // const homeRef = React.useRef();

  // const handleRefreshAlerts = () => {
  //   if (homeRef.current) {
  //     homeRef.current.fetchAlertsFromHome(-1); // 傳遞參數
  //   }
  // };

  // useEffect(() => {
  //   if (localStorage.getItem('token') == null) {
  //     return;
  //   }

  //   const connectWebSocket = () => {
  //     WebSocketManager.connect(`${window.ws_url}/channel/alerts`, localStorage.getItem('token'),
  //       (event) => {
  //         console.log('收到消息：', event.data);
  //         const data = JSON.parse(event.data);
  //         const toast_config = {
  //           position: "bottom-right",
  //           autoClose: 5 * 1000, //5 sec
  //           hideProgressBar: false,
  //           closeOnClick: false,
  //           pauseOnHover: true,
  //           draggable: true,
  //           theme:"dark"
  //         };
  //         switch (data.level) {
  //           case "ERROR":
  //             toast.error(data.message, toast_config);
  //             break;
  //           case "WARNING":
  //             toast.warning(data.message, toast_config);
  //             break;
  //           case "SUCCESS":
  //             toast.success(data.message, toast_config);
  //             break;
  //           case "INFO":
  //             toast.info(data.message, toast_config);
  //             break;
  //           case "DEFAULT":
  //           default:
  //             toast(data.message, toast_config);
  //         }
  //         if (window.location.pathname === '/') {
  //           handleRefreshAlerts()
  //         }
  //       },
  //       (error) => {
  //         console.error('WebSocket 連接錯誤：', error);
  //       },
  //       () => {
  //         console.log('WebSocket 連接已關閉');
  //         setTimeout(connectWebSocket, 5000); // 重新嘗試連接，每 5 秒嘗試一次
  //       });

  //     return () => {
  //       WebSocketManager.close();
  //     };
  //   }
  //   connectWebSocket();
  // }, []);

  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="" />
          <title>Dashboard</title>
        </Helmet>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/" element={<Home ref={homeRef} />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/factories" element={<FactoriesList />} />
          <Route path="/factories/create" element={<FactoriesForm />} />
          <Route path="/factories/:id/edit" element={<FactoriesForm />} />

          <Route path="/subcontractors" element={<SubcontractorsList />} />
          <Route path="/subcontractors/create" element={<SubcontractorsForm />} />
          <Route path="/subcontractors/:id/edit" element={<SubcontractorsForm />} />

          <Route path="/stations" element={<StationsList />} />
          <Route path="/stations/create" element={<StationsForm />} />
          <Route path="/stations/:id/edit" element={<StationsForm />} />

          <Route path="/station-components" element={<StationsComponentList />} />
          <Route path="/station-components/create" element={<StationsComponentForm />} />
          <Route path="/station-components/:id/edit" element={<StationsComponentForm />} />

          <Route path="/fw-versions" element={<FwVersionComponentList />} />
          <Route path="/fw-versions/create" element={<FwVersionComponentForm />} />
          <Route path="/fw-versions/:id/edit" element={<FwVersionComponentForm />} />

          <Route path="/lines" element={<LineList />} />
          <Route path="/lines/create" element={<LineForm />} />
          <Route path="/lines/:id/edit" element={<LineForm />} />

          <Route path="/station-types" element={<StationTypeList />} />
          <Route path="/station-types/create" element={<StationTypeForm />} />
          <Route path="/station-types/:id/edit" element={<StationTypeForm />} />

          <Route path="/products" element={<ProductList />} />
          <Route path="/products/create" element={<ProductForm />} />
          <Route path="/products/:id/edit" element={<ProductForm />} />

          <Route path="/pos" element={<PoList />} />
          <Route path="/pos/create" element={<PoForm />} />
          <Route path="/pos/:id/edit" element={<PoForm />} />

          <Route path="/user" element={<User />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/users/:id/edit" element={<UserForm />} />
          <Route path="/users/create" element={<UserForm />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/data-logs" element={<DataLogs />} />


          {/* 這裡的 * 匹配所有未定義的路由 */}
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
        <ToastContainer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
